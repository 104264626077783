// let mainSlider = {
//     el: {
//         main: '#main-slider'
//     },
//     init: function(){
//         let _t = this;

//         if(uty.detectEl($('.page-home')[0])){
//             singleThumbSlider.init({
//                 main: _t.el['main'],
//                 thumbs: '#main-slider-thumbs',
//                 wrp: '.home-slider',
//                 config: 'singleSlider'
//             });
//         }
//     }
// };

let homeFeaturedSwiper = {
    el: {
        main: '#featured-products'
    },
    addEvent: function(){
        let _t = this;

        // Tabs
        $(_t.el['main'] + ' .nav-link').on('shown.bs.tab', function (event) {
            tab = $(event.target).attr('href');
            $(tab + ' .swiper-container')[0].update();
        });
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['main'])[0]))
            _t.addEvent();
    }
};

let expandableProductWidget = {
    el: {
        main: '.epw',
        btn: '.upc-collapse button'
    },
    addEvent: function(){
        let _t = this, upc;

        // Collapsible Product Component
        $(_t.el['main'] + ' ' + _t.el['btn']).on('click', function(){
            upc = $(this);
            mnp.open({trigger: this, target: $(_t.el['main'])[0], class: 'epw-expanded', close: $('.btn-collapse, #overlay')}, {active: 'active', body: 'mnp-ready'}, function(){
                $(_t.el['main'] + ' .upc.active').removeClass('active');
            });
            upc.parents('.upc-collapsible').addClass('active');
            uty.pageScroll({top: upc.parents('.upc-collapsible').offset().top - $('.header').innerHeight()});
        });
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['main'])[0]))
            _t.addEvent();
    }
};

let corporatePages = {
    el: {
        awards: {
            main: '.cas-years',
            btn: '.cas-year',
            item: '.cas-content'
        }
    },
    awards: function(){
        let _t = this, rel;

        $(_t.el['awards']['btn']).on('click', function(){
            rel = $(this).attr('data-rel');
            $(_t.el['awards']['btn'] + '.active').removeClass('active');
            $(_t.el['awards']['item'] + '.active').removeClass('active');
            $(this).addClass('active');
            $(_t.el['awards']['item'] + '[data-rel="' + rel + '"]').addClass('active');
        });
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($('.corporate-pages')[0]))
            for(i in _t.el)
                if(uty.detectEl($(_t.el[i]['main'])[0]))
                    _t[i]();
    }
};

let atsController = {
    el: {
        main: '.ats',
        item: '.acc-item',
        trigger: '.ats .acc-header',
        tab: '.ats-tab'
    },
    template: {
        menu: '<div class="ats-menu">{{ITEM}}</div>',
        item: '<button type="button" class="ats-tab"><span>{{TXT}}</span></button>'
    },
    init: function(){
        let _t = this, html = '', prn, inx;

        if( ! isMobile && uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['trigger']).each(function(){
                html += _t.template['item'].replace('{{TXT}}', $('button', this).text());
            });
            $(_t.el['main']).prepend(_t.template['menu'].replace('{{ITEM}}', html));
            $(_t.el['tab'] + ':first').addClass('active');
            $(_t.el['tab']).on('click', function(){
                inx = $(this).index();
                prn = $(this).parents(_t.el['main']);
                $(this).siblings(_t.el['tab'] + '.active').removeClass('active');
                $(_t.el['item'] + '.active', prn).removeClass('active');
                $(this).addClass('active');
                $(_t.el['item'] + ':eq(' + inx + ')', prn).addClass('active');
            });
        }
    }
};

let corporateMenu = {
    el: {
        main: '.cpm',
        item: '.cpm-item',
        link: '.has-sub .cpm-link'
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['link']).on('click', function(){
                $(this).parents(_t.el['item']).toggleClass('selected');
            });
        }
    }
};

// Media Popup
let mediaPopup = {
    el: {
        video:{
            main: '.mnp-video-trigger'
        },
        image: {
            main: '.mnp-image-trigger'
        }
    },
    template: {
        main: '<div class="mnp mnp-m-center mnp-d-center mnp-lg mnp-headerless mnp-{{TYPE}}"><div class="mnp-header"><button class="mnp-close" aria-label="kapat"></button></div><div class="mnp-body">{{CONTENT}}</div></div>',
        video: '<video src="{{SRC}}" controls></video>',
        youtube: '<div class="yt-container"><figure class="responsive"><iframe src="{{SRC}}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></figure></div>',
        image: '<img src="{{SRC}}" />'
    },
    video: function(){
        let _t = this, src, type;

        $(_t.el['video']['main']).on('click', function(){
            src = $(this).attr('data-video'), type = $(this).attr('data-source');

            body.append(_t.template['main'].replace('{{CONTENT}}', _t.template[type].replace('{{SRC}}', src)).replace('{{TYPE}}', 'video'));
            mnp.open({trigger: this, target: $('.mnp-video')[0], close: $('.mnp-close, #overlay')}, {}, function(){
                $('.mnp-video').remove();
            });
        });
    },
    image: function(){
        let _t = this, src;

        $(_t.el['image']['main']).on('click', function(){
            src = $(this).attr('data-image');

            body.append(_t.template['main'].replace('{{CONTENT}}', _t.template['image'].replace('{{SRC}}', src)).replace('{{TYPE}}', 'image'));
            mnp.open({trigger: this, target: $('.mnp-image')[0], close: $('.mnp-close, #overlay')}, {}, function(){
                $('.mnp-image').remove();
            });
        });
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['video']['main'])[0]))
            _t.video();

        if(uty.detectEl($(_t.el['image']['main'])[0]))
            _t.image();
    }
};

// Category Swiper
let categorySwiper = {
    el: {
        main: '#category-swiper'
    },
    init: function(){
        let _t = this;

        if( ! isMobile && uty.detectEl($(_t.el['main'])[0])){
            if($(_t.el['main'] + ' .swiper-slide').length < 6)
                $(_t.el['main']).addClass('aligned');
        }
    }
};

// Recent Products
let recentProducts = {
    el: {
        main: '#recent-products',
        btn: '.rpm-toggle'
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['btn']).on('click', function(){
                $(_t.el['main']).toggleClass('rpm-ready');
            });
        }
    }
};

// Marketplace Store Menu
let MPStoreMenu = {
    el: {
        main: '.msd-sidebar'
    },
    init: function(){
        let _t = this, id;

        if(uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['main'] + ' a').on('click', function(e){
                e.preventDefault();
                id = $(this).attr('href');
                uty.pageScroll({top: $(id).offset().top - ((isMobile) ? $('.header').innerHeight() : 113)});
            });
        }
    }
};

// SSH Store Finder
let SSHStoreFinder = {
    el: {
        main: '.sco-banner',
        btn: '.sco-banner .btn-find-store'
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['btn']).on('click', function () {
                mdl = $(this).attr('data-target');
                if( $(mdl).length > 0 )
                    storesModal.init(mdl);
            });
        }
    }
};

// SSH Menu
let SSHMenu = {
    el: {
        main: '#ssh-menu',
        item: '.ssh-menu-link'
    },
    init: function(){
        let _t = this, inx;

        if(uty.detectEl($(_t.el['main'])[0])){
            inx = $(_t.el['item'] + '.active').parents('.swiper-slide').index();

            document.querySelector(_t.el['main']).focused(inx);
        }
    }
};

// Main Slider
if(uty.detectEl($('#main-slider')[0])){
    var m99Slider;
    $.cachedScript(extJSPath + 'hammer.min.js').done(function(script, textStatus) {
        var reqAnimationFrame = (function () {
            return window[Hammer.prefixed(window, 'requestAnimationFrame')] || function (callback) {
                window.setTimeout(callback, 1000 / 60);
            };
        })();
        
        var screen = document.querySelector("#hitbox");
        var el = document.querySelector("#hitarea");
        var slides = document.querySelectorAll(".tekno-slide");
        var thumbs = [];
        var thumbsHolder = document.querySelector(".tekno-thumbs");
        var teknoSlider = document.querySelector(".tekno-slider");
        var orderHolder = $(".tekno-order");
        
        var START_X = 0;
        var START_Y = 0;
        
        var ticking = false;
        var transform;
        var timer;
        
        var currentSlide = null;
        var previousSlide = null;
        var currentIndex = 0;
        var mc = new Hammer.Manager(el);
        var mscInit = true;

        mc.add(new Hammer.Pan({ threshold: 0, pointers: 0, direction:Hammer.DIRECTION_HORIZONTAL }));
        mc.add(new Hammer.Swipe({direction:Hammer.DIRECTION_HORIZONTAL})).recognizeWith(mc.get('pan'));
        mc.add(new Hammer.Rotate({ threshold: 0 })).recognizeWith(mc.get('pan'));
        mc.add(new Hammer.Pinch({ threshold: 0 })).recognizeWith([mc.get('pan'), mc.get('rotate')]);
        mc.add(new Hammer.Tap({ event: 'doubletap', taps: 2 }));
        mc.add(new Hammer.Tap());

        mc.on("panstart panmove", onPan);
        mc.on("swipe", onSwipe);
        mc.on("tap", onTap);
        mc.on("hammer.input", function(ev) {
            if(ev.isFinal) {
                resetElement();
            }
        });

        let shadeSize = (isMobile) ? 6.4 : 5.6;
        m99Slider = {
            timer: false,
            select:function(n){
                if(n < 0)
                    n = slides.length - 1;
                else if(n > slides.length - 1)
                    n = 0;

                if(n == 0){
                    $('.tekno-prev').addClass('disabled');
                    $('.tekno-next').removeClass('disabled');
                }else if(n == (slides.length - 1)){
                    $('.tekno-next').addClass('disabled');
                    $('.tekno-prev').removeClass('disabled');
                }else{
                    $('.tekno-next').removeClass('disabled');
                    $('.tekno-prev').removeClass('disabled');
                }
        
                currentSlide = slides[n];
                previousSlide = n > 0 ? slides[n-1] : null;
                currentIndex = n;
                this.loadImage(currentIndex);

                orderHolder.find('.curr').text(currentIndex + 1);
        
                let selectedThumb = thumbsHolder.querySelector(".selected");
                    if(selectedThumb) selectedThumb.classList.remove("selected");
                
                for(var i=0; i<slides.length; ++i){
                    let slide = slides[i];
                    let span = slide.querySelector(".msc-shade");
                    if( i < n){
                        slide.style.transform = "translate("+((-100 * n) - 10)+"%, 0) scale(1.1)";
                        slide.classList.remove("shaded");
                    }else if(i == n){
                        slide.style.transform = "translate("+(-100 * i)+"%, 0) scale(1)";
                        slide.classList.remove("shaded");
                        thumbs[n].classList.add("selected");
                    }else if( i > n){
                        slide.style.transform = "translate("+ (((-100 * (i)) + (shadeSize * (i-n)))) +"%, 0) scale("+ (1 - .08 * (i-n)) +")";
                        slide.classList.add("shaded");
                        if(i-n == 1)
                            span.style.backgroundColor = "#555473";
                        else if(i-n == 2)
                            span.style.backgroundColor = "#9E9EAF";
                        else if(i-n == 3)
                            span.style.backgroundColor = "#E7E7EB";
                        else
                            span.style.backgroundColor = "#ffffff"; 
                    }
                }
            },
            next:function(){
                this.select(currentIndex+1);
            },
            prev:function(){
                this.select(currentIndex-1);
            },
            loadImage: function(inx){
                const mscMedia = slides[inx].querySelector('.msc-media');
                const mscImg = mscMedia.querySelector('img');
                if(!$(mscMedia).hasClass('touched')){
                    mscMedia.classList.add('touched', 'msc-loading');
                    const newImg = new Image();
                    newImg.onload = function(){
                        mscImg.src = this.src;
                        mscMedia.classList.remove('msc-loading');
                        mscMedia.classList.add('msc-loaded');
                    };
                    newImg.src = mscImg.getAttribute('data-src');
                }
            },
            init:function(){
                let totalSlide = slides.length, _t = this, auto = document.querySelector('#main-slider').hasAttribute('data-slider-delay');

                for(var i=0; i<totalSlide; ++i){
                    let slide = slides[i];
                    slide.setAttribute("data-id", i);
                    slide.style.zIndex = totalSlide - i;
                    slide.style.transform = "translate("+ (((-100 * (i)) + (shadeSize * (i)))) +"%, 0) scale("+ (1 - .08 * (i)) +")";
        
                    let thumb = document.createElement("div");
                        thumb.innerHTML =   '<button onclick="m99Slider.select('+i+')" data-id="'+i+'" type="button">'
                                            +'<img srcset="'+ slide.getAttribute("data-thumb") +'">'
                                            +'</button>';
        
                    thumbsHolder.append(thumb);
                    thumbs.push(thumb);
                }
                this.select(0);
                orderHolder.append('<span class="curr">1</span> / <span class="ttl">' + totalSlide + '</span>');

                setTimeout(function(){
                    mscInit = false;
                    teknoSlider.classList.add("animate");
                }, 333);

                if(auto){
                    _t.timer = setInterval(function(){
                        _t.next();
                    }, parseFloat($('#main-slider').attr('data-slider-delay')) * 1000);
                }
                // Bind actions
                // $('.tekno-next').off('click').on('click', function(){
                //     _t.next();
                // });
                // $('.tekno-prev').off('click').on('click', function(){
                //     _t.prev();
                // });
            }
        }
        
        // function logEvent(ev) {
        //     //el.innerText = ev.type + ev.offsetDirection + " : " + ev.deltaX + " / " + (Math.min(1, Math.abs(ev.deltaX / screen.offsetWidth)));
        // }
        
        function resetElement() {
            if(!mscInit)
                teknoSlider.classList.add("animate");
            transform = {
                translate: { x: START_X, y: START_Y },
                scale: 1,
                angle: 0,
                rx: 0,
                ry: 0,
                rz: 0
            };
        
            currentSlide.style.transform = "translate("+(-100 * currentIndex)+"%, 0) scale(1)";
            if(previousSlide != null){
                previousSlide.style.transform = "translate("+((-100 * currentIndex-1) - 10)+"%, 0) scale(1.1)";
            }
        }
        
        function updateElementTransform(ev) {
            
            ticking = false;
        
            let k = Math.min(1, Math.abs(ev.deltaX / screen.offsetWidth));
        
            if(ev.offsetDirection == 4){
                currentSlide.style.transform = "translate("+ ((-100 * currentIndex) + (5.4 * k)) +"%, 0) scale("+ (1 - (.08 * k)) +")";
                if(previousSlide != null){
                    previousSlide.style.transform = "translate("+ ((-100 * (currentIndex)) + 0 + (60 * k)) +"%, 0) scale("+ (1 + (.08 * (1-k))) +")";
                }
            }else if(ev.offsetDirection == 2){
                currentSlide.style.transform = "translate("+((-100 * currentIndex) - (60 * k))+"%, 0) scale("+(1 + (.08 * k))+")";
            }
        }
        
        function requestElementUpdate(ev) {
            if(!ticking) {
                reqAnimationFrame(function(){ updateElementTransform(ev); });
                ticking = true;
            }
        }
        
        function onPan(ev) {
        
            teknoSlider.classList.remove("animate");
        
            // logEvent(ev);
            requestElementUpdate(ev);
        }
        
        function onSwipe(ev) {

            clearTimeout(timer);
            timer = setTimeout(function () {
                resetElement();
            }, 99);
        
            m99Slider.select( ev.offsetDirection == 2 ? currentIndex + 1 : currentIndex - 1 );
        
            // logEvent(ev);
        
        }

        function onTap(ev){
            location.href = $('.tekno-container .tekno-slide:eq(' + currentIndex + ') .msc-button .btn').attr('href');
        }

        m99Slider.init();

        resetElement();
    });
}

// LP Subtab
let LPSubtab = {
    el: {
        main: '.lsc-subtabs'
    },
    init: function(){
        let _t = this;
        
        if(isMobile && uty.detectEl($(_t.el['main'])[0]) && uty.detectEl($(_t.el['main'] + ' .active')[0])){
            $(_t.el['main']).scrollLeft($(_t.el['main'] + ' .active').position().left - 70);
        }
    }
};

// Curated Share
const curatedShare = {
    el: {
        list: '.page-curated-collection-list',
        detail: '.page-curated-collection-detail',
        input: '.psh-link-cta input'
    },
    init: function() {
        const _t = this;

        if(uty.detectEl($('.cci-share')[0])){
            let url = window.location.href;

            $('.cci-share').off('click').on('click', function(){
                if(uty.detectEl($(_t.el['list'])[0])){
                    url = $(this).attr('data-collection-url');
                }

                $(_t.el['input']).val(url);
                mnp.open({target: $('#mnp-curated-share')[0], close: $('.mnp-close, #overlay')});
            });
        }
    }
};

// Campaign Share
const campaignShare = {
    el: {
        list: '.page-campaigns',
        detail: '.page-campaign-products',
        input: '.psh-link-cta input'
    },
    init: function() {
        const _t = this;

        if(uty.detectEl($('.cri-share')[0])){
            let url = window.location.href;

            $('.cri-share').off('click').on('click', function(){
                if(uty.detectEl($(_t.el['list'])[0])){
                    url = $(this).attr('data-collection-url');
                }else{
                    if($('#mnp-similar-campaigns').hasClass('mnp-active')){
                        mnp.destroy({target: $('#mnp-similar-campaigns')[0]});
                    }
                }

                $(_t.el['input']).val(url);
                mnp.open({target: $('#mnp-campaign-share')[0], close: $('.mnp-close, #overlay')});
            });
        }
    }
};

// SSH Warranty Compare
const SSHWarrantyCompare = {
    el: {
        main: '.sew-compare',
        col: '.sec-val',
        lbl: '.sec-label',
        row: '.sec-row',
        head: '.sec-head',
        tab: '.sec-tab'
    },
    template: {
        main: '<div class="sec-tabs scrollable-h">{{TAB}}</div>',
        tab: '<button class="sec-tab" type="button">{{TITLE}}</button>'
    },
    select: function(inx) {
        const _t = this;

        $(_t.el['col'] + '.active').removeClass('active');
        $(_t.el['tab'] + '.active').removeClass('active');

        $(_t.el['row']).each(function(){
            $(_t.el['col'] + ':eq(' + inx + ')', this).addClass('active');
        });

        $(_t.el['tab'] + ':eq(' + inx + ')').addClass('active');
    },
    prepare: function() {
        const _t = this;

        // Prepare tabs
        let html = '';

        $(_t.el['head']).each(function(){
            html += _t.template.tab.replace('{{TITLE}}', $(this).text());
        });

        $(_t.el['main'] + ' table').before(_t.template.main.replace('{{TAB}}', html));

        // Prepare table
        _t.select(0);

        // Add event
        $(_t.el['tab']).off('click').on('click', function(){
            const inx = $(this).index();

            _t.select(inx);
        });
    },
    init: function() {
        const _t = this;

        if(uty.detectEl($(_t.el['main'])[0]) && isMobile){
            _t.prepare();
        }
    }
};