let plpEvents = {
    el: {
        plpFilters: {
            main: '#filters',
            btn: '.btn-filters',
            close: '.flt-close'
        },
        plpSort: {
            main: '.srt',
            btn: '.btn-sort',
            close: '.srt-close'
        },
        plpBar: {
            main: '.plp-cta'
        },
        plpViews: {
            main: '.plp-views',
            col1: '.pvo-col1',
            col2: '.pvo-col2',
            col3: '.pvo-col3',
            col4: '.pvo-col4'
        },
        plpCompare: {
            main: '.plp-compare',
            btn: '.plp-compare input[type="checkbox"]'
        },
        plpEditorials: {
            main: '.plp-editorials',
            tab: '.pec-tabs',
            swiper: '.content-swiper'
        }
    },
    plpFilters: function(){
        let _t = this;

        if(isMobile){
            // Popup Set
            $(_t.el['plpFilters']['btn']).on('click', function(){
                mnp.open({trigger: this, target: $(_t.el['plpFilters']['main'])[0], class: 'plp-filter-ready', close: $(_t.el['plpFilters']['close'] + ', #overlay')}, {active: 'active', body: 'mnp-ready'});
            });
        }else{
            siderbar = new StickySidebar('.plp-filters', {
                containerSelector: '.plp-grid',
                innerWrapperSelector: '#filters',
                topSpacing: 130,
                bottomSpacing: 20
            });
        }
        
        // Accordion
        accordion.init($(_t.el['plpFilters']['main'])[0], {main: '.flt-groups', item: '.fgp', btn: '.fgp-title'}, function(ths){
            // uty.pageScroll({top: $(ths).offset().top - $('.header').innerHeight()});
            if( ! isMobile){
                console.log('TEST');
                setTimeout(() => {
                    siderbar.updateSticky();
                }, 120);
            }
        });
    },
    plpSort: function(){
        let _t = this;

        $(_t.el['plpSort']['btn']).on('click', function(){
            mnp.open({trigger: this, target: $(_t.el['plpSort']['main'])[0], class: 'plp-sort-ready', close: $(_t.el['plpSort']['close'] + ', #overlay')}, {active: 'active', body: 'mnp-ready'});
        });
    },
    plpBar: function(){
        let _t = this;

        $(_t.el['plpBar']['main']).addClass('se-item').attr('data-se-anim', 'plpBar');
    },
    plpViews: function(){
        let _t = this;

        if(isMobile){
            $(_t.el['plpViews']['col1']).on('click', function(){
                body.addClass('plp-view-scaled');
            });
            $(_t.el['plpViews']['col2']).on('click', function(){
                body.removeClass('plp-view-scaled');
            });
        }else{
            $(_t.el['plpViews']['col3']).on('click', function(){
                body.addClass('plp-view-scaled');
            });
            $(_t.el['plpViews']['col4']).on('click', function(){
                body.removeClass('plp-view-scaled');
            });
        }
    },
    plpCompare: function(){
        let _t = this, val;

        $(_t.el['plpCompare']['btn']).on('change', function(){
            val = $(this).prop('checked');
            
            if(val)
                body.addClass('plp-compare-ready');
            else
                body.removeClass('plp-compare-ready');
        });
    },
    plpEditorials: function(){
        let _t = this, tab;

        $(_t.el['plpEditorials']['tab'] + ' .nav-link').on('shown.bs.tab', function (event) {
            tab = $(event.target).attr('href');
            $(tab + ' ' + _t.el['plpEditorials']['swiper'])[0].update();
        });
    },
    init: function(){
        let _t = this;

        for(i in _t.el)
            if(uty.detectEl($(_t.el[i]['main'])[0]))
                _t[i]();
    }
};

// Seo
// let seoContent = {
//     el: {
//         main: '.seo-more',
//         btn: '.seo-cta .btn'
//     },
//     init: function(){
//         let _t = this;

//         if(uty.detectEl($(_t.el['main'])[0]))
//             $(_t.el['btn']).on('click', function(){
//                 $(this).parents(_t.el['main']).removeClass('seo-more');
//             });
//     }
// };

// Campaigns
const similarCampaigns = {
    el: {
        main: '#mnp-similar-campaigns',
        detail: '#mnp-campaign-details',
        btn: '.cdr-similar'
    },
    init: function(){
        const _t = this;

        if(uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['detail'] + ' ' + _t.el['btn']).off('click').on('click', function(){
                mnp.destroy({trigger: $('.btn-campaign-rules')[0], target: $(_t.el['detail'])[0]});
                mnp.open({trigger: $(_t.el['detail'] + ' ' + _t.el['btn'])[0], target: $(_t.el['main'])[0], close: $('.mnp-close, #overlay')});
            });
        }
    }
};