let singleThumbSlider = {
    config: SITE_CONFIG.plugins.swiper,
    template: {
        slider: '<div class="swiper-container" id="{{ID}}" thumbsSlider><div class="swiper-wrapper">{{THUMBS}}</div><div class="swiper-button-next" aria-label="sonraki"></div><div class="swiper-button-prev" aria-label="onceki"></div><div class="swiper-pagination"></div></div>',
        slide: '<div class="swiper-slide {{TYPE}}"><figure class="responsive"><img data-srcset="{{SRCSET}}" class="lazy" alt="{{ALT}}" /></figure></div>'
    },
    prepareThumbs: function(opt){
        let _t = this, src, html = '', type;

        $(opt['main'] + ' .swiper-slide').each(function(i){
            src = $(this).attr('data-thumb');
            type = $(this).attr('data-type');
            html += _t.template['slide'].replace('{{SRCSET}}', src).replace('{{ALT}}', 'slider thumb ' + (i+1)).replace('{{TYPE}}', 'thumb-' + type);
        });
        $(opt['wrp']).append(_t.template['slider'].replace('{{THUMBS}}', html).replace('{{ID}}', opt['thumbs'].replace('#', '')));
        lazy.update();
    },
    init: function(opt){
        let _t = this, slider, sliderThumbs;

        if( ! isMobile){
            // Gallery Thumbs
            _t.prepareThumbs(opt);
            sliderThumbs = new Swiper(opt['thumbs'], _t.config['singleSliderThumbs']);

            // Gallery Config Update
            _t.config[opt['config']]['thumbs'] = {swiper: sliderThumbs};
        }

        slider = new Swiper(opt['main'], _t.config[opt['config']]);
    }
};

let pdpGallery = {
    el: {
        main: '#pdp-gallery',
        zoom: '.pdp-zoom',
        close: '.pdp-zoom-close',
        slide: '.swiper-slide[data-type="image"]'
    },
    template: {
        slider: '<div class="pdp-zoom"><button class="pdp-zoom-close"></button><div class="swiper-container" data-swiper="singleSlider"><div class="swiper-wrapper">{{SLIDES}}</div><div class="swiper-button-next" aria-label="sonraki"></div><div class="swiper-button-prev" aria-label="onceki"></div><div class="swiper-pagination"></div></div></div>',
        slide: '<div class="swiper-slide"><figure class="responsive"><img data-src="{{SRC}}" class="lazy" alt="{{ALT}}" /></figure></div>'
    },
    prepareZoom: function(){
        let _t = this, src, html = '';

        $(_t.el['main'] + ' ' + _t.el['slide']).each(function(i){
            src = $(this).attr('data-zoom');
            html += _t.template['slide'].replace('{{SRC}}', src).replace('{{ALT}}', 'slider zoom ' + (i+1));
        });
        $(body).append(_t.template['slider'].replace('{{SLIDES}}', html));
        lazy.update();
        $(_t.el['zoom'] + ' .swiper-container').addClass('swiper-activated');
        new MinusSwiper({ ID: $(_t.el['zoom'] + ' .swiper-container')[0] });
        _t.zoomEvents();
    },
    zoomEvents: function(){
        let _t = this, inx, src;

        // Open Zoom
        $(_t.el['main'] + ' ' + _t.el['slide']).on('click', function(){
            src = $(this).attr('data-zoom');
            $(_t.el['main'] + ' ' + _t.el['slide']).each(function(i){
                if($(this).attr('data-zoom') == src){
                    inx = i;
                    return false;
                }
            });
            $(_t.el['zoom'] + ' .swiper-container')[0].focused(inx);
            $(body).addClass('pdp-zoom-ready');
            $(_t.el['zoom'] + ' .swiper-container')[0].update();
        });
        // Close Zoom
        $(_t.el['close']).on('click', function(){
            $(body).removeClass('pdp-zoom-ready');
        });
        $('#overlay').off('click').on('click', function(){
            $(body).removeClass('pdp-zoom-ready');
        });
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($('.page-pdp')[0])){
            singleThumbSlider.init({
                main: '#pdp-gallery',
                thumbs: '#pdp-gallery-thumbs',
                wrp: '.pdp-gallery',
                config: 'pdpGallery'
            });

            if( ! isMobile)
                _t.prepareZoom();
        }
    }
};

let pdpSections = {
    el: {
        main: '.page-pdp',
        sct: '.pdp-section',
        acc: {
            main: '.pdp-accordion',
            item: '.pdp-section',
            btn: '.pdp-acc-trg'
        },
        desc: {
            main: '#pdp-description',
            btn: '.pdp-desc-more',
            title: '.pdp-desc-title'
        }
    },
    template: {
        wrp: '<div class="psm"><div class="psm-header"><div class="psm-title">{{TITLE}}</div></div><ul class="ul-clear psm-menu container-max-1300">{{MENU}}</ul></div>',
        item: '<li><button type="button" class="psm-item" data-rel="{{REL}}">{{ITEM}}</button></li>'
    },
    checkObserve: function(elm){
        $('.psm-item.active').removeClass('active');
        $('.psm-item[data-rel="#' + elm.id + '"]').addClass('active');
    },
    addEvent: function(){
        let _t = this, mdl;

        accordion.init($('.pdp')[0], _t.el['acc'], function(ths){
            uty.pageScroll({top: $(ths).offset().top - _t.calcDist()});
            if($(ths).hasClass('active')){
                $('.psm-menu .psm-item.active').removeClass('active');
                $('.psm-menu .psm-item[data-rel="#' + $(ths).attr('id') + '"]').addClass('active');
            }
        });

        if(uty.detectEl($(_t.el['desc']['main'])[0])){
            $(_t.el['desc']['btn'] + ', ' + _t.el['desc']['title']).on('click', function(){
                $(_t.el['desc']['main']).toggleClass('active');
                uty.pageScroll({top: $(_t.el['desc']['main']).offset().top - _t.calcDist(), behavior: 'auto'});
            });
        }

        // Observer for Sections
        let observer = new IntersectionObserver(function(entries, observer){
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                  let elm = entry.target;
            
                  if(entry.intersectionRatio >= 0.1){
                    if(elm.id == 'pdp-main' || elm.id == 'pdp-description'){
                        _t.checkObserve(elm);
                    }else{
                        if($(elm).hasClass('active')){
                            _t.checkObserve(elm);
                        }
                    }
                  }
                }
            });
        },{
            threshold: 0.1
        });
        
        $('.pdp-section').each(function(){
            observer.observe(this);
        });

        $('.prr-more .go-top').on('click', function(){
            uty.pageScroll({top: $('#pdp-reviews').offset().top - _t.calcDist()});
        });

        // Map Modals
        $('.pdp .btn-collection, .btn-find-store, #mnp-iklimsa-notice .btn-add').on('click', function () {
            mdl = $(this).attr('data-target');
            if( $(mdl).length > 0 )
                storesModal.init(mdl);
        });
    },
    menu: function(){
        let _t = this, menu, items = '', id;

        // Prepare Menu
        $(_t.el['sct']).each(function(){
            items += _t.template['item'].replace('{{ITEM}}', $(this).attr('data-title')).replace('{{REL}}', '#' + $(this).attr('id'));
        });
        menu = _t.template['wrp'].replace('{{TITLE}}', $('.pdp-title').html()).replace('{{MENU}}', items);
        $('.pdp').append(menu);
        $('.psm-menu li:first .psm-item').addClass('active');
        // Menu Items Event
        $('.psm-item').on('click', function(){
            id = $(this).attr('data-rel');
            
            $('.psm-item.active').removeClass('active');
            $(this).addClass('active');

            if( ! $(id).hasClass('active'))
                $(id).addClass('active');

            uty.pageScroll({top: $(id).offset().top - _t.calcDist()});
            mnp.destroy({trigger: $('.psm-trigger')[0], target: $('.psm')[0], class: 'psm-ready'}, {active: 'active', body: 'mnp-ready'});
        });

        if(isMobile){
            $('.psm-trigger').on('click', function(){
                mnp.open({trigger: this, target: $('.psm')[0], class: 'psm-ready', close: $('.psm-header, .psm-closer, #overlay')}, {active: 'active', body: 'mnp-ready'});
            });
        }
    },
    goToSection: function(){
        let _t = this, target;

        $('.go-section').on('click', function(){
            target = $(this).attr('data-target');
            uty.pageScroll({top: $(target).offset().top - _t.calcDist()});
            $(target).addClass('active');
        });
    },
    calcDist: function(){
        let _t = this, hdr = $('.header').innerHeight();

        if( ! isMobile && hdr < 120)
            hdr = hdr + 53;
        
            return hdr;
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['main'])[0])){
            _t.addEvent();
            _t.menu();
            _t.goToSection();
        }
    }
};

let pdpBenefits = {
    el: {
        main: '.pbt-tabs',
        tab: '.pbt-tab',
        content: '.pbt-content'
    },
    init: function(){
        let _t = this, inx;

        if(uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['tab']).on('click', function(){
                if( ! uty.detectEl($('.pbt-active')[0]))
                    $(_t.el['main']).addClass('pbt-active');
                
                if( ! $(this).hasClass('selected')){
                    $(_t.el['content'] + '.active').removeClass('active');
                    $(_t.el['tab'] + '.selected').removeClass('selected');
                    $(this).addClass('selected');
                    inx = $(this).index();
                    $(_t.el['content'] + ':eq(' + inx + ')').addClass('active');
                }
            });
        }
    }
};

let breadcrumb = {
    el: {
        main: '.breadcrumb'
    },
    cls: 'brc-reveal',
    template: {
        main: '<div class="bcm"></div>',
        item: '<div class="bcm-row"><div class="bcm-row-header">{{ROW}}</div></div>'
    },
    prepareOverlay: function(){
        let _t = this, curr;
        
        $(_t.el['main']).after(_t.template['main']);
        $(_t.el['main'] + ' li').each(function(e){
            curr = _t.template['item'].replace('{{ROW}}', $(this).html());

            if(e == 0)
                $('.bcm').append(curr);
            else
                $('.bcm-row:eq(' + (e-1) + ')').append(curr);
        });
    },
    addEvent: function(){
        let _t = this, len = $(_t.el['main'] + ' li').length;

        if(len < 4){
            $(_t.el['main']).addClass(_t.cls);
        }else{
            _t.prepareOverlay();
            $(_t.el['main']).on('click', function(e){
                e.preventDefault();
                mnp.open({trigger: this, target: $('.bcm')[0], class: 'bcm-ready', close: $('#overlay')}, {active: 'active', body: 'mnp-ready'});
            });
        }
    },
    init: function(){
        let _t = this;

        if(isMobile && uty.detectEl($(_t.el['main'])[0]))
            _t.addEvent();
    }
};

let createList = {
    el: {
        main: '.plm',
        btn: '.plm-toggle'
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['btn']).on('click', function(){
                $(_t.el['main']).toggleClass('plm-form-ready');
            });
        }
    }
};

let asqRules = {
    el: {
        main: '#modal-ask-to-seller',
        btn: '.asq-rules-trigger',
        close: '.asq-rules-close'
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['btn']).on('click', function(){
                $(_t.el['main']).addClass('asq-rules-ready');
            });
            $(_t.el['close']).on('click', function(){
                $(_t.el['main']).removeClass('asq-rules-ready');
            });
        }
    }
}

// Copy to Clipboard
let copyToClipboard = {
    el: {
        main: '#clipboard'
    },
    template: '<input type="text" id="clipboard" value="{{VAL}}" tabindex="-1" aria-hidden="true" readonly />',
    init: function(str, fn){
        let _t = this, elm;

        body.append(_t.template.replace('{{VAL}}', str));
        elm = $(_t.el['main']);
        elm.trigger('select');
        document.execCommand("copy");
        elm.remove();

        if(typeof fn != 'undefined')
            fn();
    }
};

// let copyToClipboard = {
//     init: function(str, fn){
//         navigator.clipboard.writeText(str).then(function() {
//             if(typeof fn != 'undefined')
//                 fn();
//         }, function(error) {
//             console.log(error);
//         });
//     }
// };

// Share
// obj = {title: '', url: ''};
let shareProduct = {
    // uri: {
    //     fb: 'https://www.facebook.com/sharer/sharer.php?u={{URL}}',
    //     tw: 'https://twitter.com/intent/tweet?text=&url={{URL}}'
    // },
    // mobile: function(obj){
    //     if(navigator.share){
    //         console.log('Web Share API is Supported');
    //         navigator.share(obj).then(function(){
    //             console.log('Shared!', obj);
    //         }).catch(function(error){
    //             console.log('Not Shared! ', error, obj);
    //         });
    //     }else{
    //         console.log('Web Share API is not Supported');
    //     }
    // },
    // desktop: function(obj){
    //     let _t = this, url = encodeURIComponent(obj['url']), ths;

    //     $('.psh-fb').attr('href', _t.uri.fb.replace('{{URL}}', url));
    //     $('.psh-tw').attr('href', _t.uri.tw.replace('{{URL}}', url));
    //     $('.psh-cp').on('click', function(){
    //         ths = $(this);
    //         copyToClipboard.init(obj['url'], function(){
    //             ths.addClass('copied');
    //             setTimeout(function(){
    //                 ths.removeClass('copied');
    //             }, 3000);
    //         });
    //     });
    // },
    copy: function(){
        let uri = $('.psh-link-cta input').val();

        $('.psh-link-cta').on('click', function(){
            ths = $(this);
            copyToClipboard.init(uri, function(){
                ths.addClass('copied');
                setTimeout(function(){
                    ths.removeClass('copied');
                }, 3000);
            });
        });
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($('.psh-share')[0])){
            // let obj = {title: $('.pdp-title').text(), url: window.location.href};

            // $('.pdp-controls .btn-share').on('click', function(){
            //     if(isMobile){
            //         _t.mobile(obj);
            //     }else{
            //         _t.desktop(obj);
            //         $('#modal-pdp-share').modal('show');
            //     }
            // });
            _t.copy();
        }
    }
};

let prdcode = {
    el: {
        main: '.page-pdp',
        prd: '.pdp-prdcode',
        src: '.pdp-gallery'
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($('.page-pdp')[0]) && isMobile){
            $(_t.el['prd']).appendTo(_t.el['src']);
        }
    }
};

let creditOfferBadge = {
    el: {
        holder: '.pdp-badges',
        main: '.prd-badge.btn-credit-offer',
        section: '#pdp-payment'
    },
    init: function() {
        let _t = this, pym = $(_t.el['section']);

        if(uty.detectEl($(_t.el['holder'] + ' ' + _t.el['main'])[0])){
            $(_t.el['holder'] + ' ' + _t.el['main']).on('click', function(){
                if( ! pym.hasClass('active'))
                    pym.addClass('active');
                uty.pageScroll({top: pym.offset().top - pdpSections.calcDist()});
                $(_t.el['section'] + ' .nav-link[data-rel="credit"]').trigger('click');
            });
        }
    }
};