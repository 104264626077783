// let loginRegister = {
//     el: {
//         main: '.page-login-register',
//         lrm: {
//             main: '.lrm',
//             item: '.lrm-item',
//             btn: '.lrm-header > button'
//         }
//     },
//     init: function(){
//         let _t = this;

//         if(uty.detectEl($(_t.el['main'])[0])){
//             accordion.init(document, {main: _t.el['lrm']['main'], item: _t.el['lrm']['item'], btn: _t.el['lrm']['btn']});
//         }
//     }
// };

let chaAccordion = {
    el: {
        main: '.cha',
        item: '.cha-item',
        btn: '.cha-header > button'
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['main'])[0]))
            accordion.init(document, {main: _t.el['main'], item: _t.el['item'], btn: _t.el['btn']});
    }
};

let forgotPasswordOpt = {
    el: {
        main: '.frp-options'
    },
    init: function(){
        let _t = this, st, opt;

        if(uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['main'] + ' input:radio').on('change', function(){
                st = $(this).prop('checked');
                opt = $(this).attr('data-opt');

                if(st){
                    $('.frp-form .active').removeClass('active');
                    $('.frp-form [data-opt="' + opt + '"]').addClass('active');
                }
            });
        }
    }
};

let memberSidebar = {
    el:{
        main: '.mmb-aside'
    },
    init: function(){
        let _t = this;
        
        if(uty.detectEl($(_t.el['main'])[0])){
            if( ! isMobile){
                siderbar = new StickySidebar(_t.el['main'], {
                    containerSelector: '.mmb-grid',
                    innerWrapperSelector: '.mmb-sidebar',
                    topSpacing: 130,
                    bottomSpacing: 20
                });
            }
        }
    }
};

let orderCancel = {
    el: {
        main: '.page-order-cancel',
        item: '.uoc-item',
        sel: '.uoc-item-select',
        all: '.uoc-rows-all'
    },
    init: function(){
        let _t = this, ths;

        if(uty.detectEl($(_t.el['main'])[0])){
            // Select
            $(_t.el['sel'] + ' input:checkbox').on('change', function(){
                if($(this).prop('checked'))
                    $(this).parents(_t.el['item']).addClass('selected');
                else
                    $(this).parents(_t.el['item']).removeClass('selected');
            });
            // All
            $(_t.el['all'] + ' input:checkbox').on('change', function(){
                ths = $(this).prop('checked');
                $(_t.el['item']).each(function(){
                    $(this).find(_t.el['sel'] + ' input:checkbox').prop('checked', ths);
                    if(ths)
                        $(this).addClass('selected');
                    else
                        $(this).removeClass('selected');
                });
            });
        }
    }
}

let orderReturn = {
    el: {
        main: '.page-order-return',
        copy: {
            main: '.cpr-code',
            btn: '.cpr-code-copy',
            body: '.cpr-code-body'
        },
        // type: {
        //     main: '.upr-types',
        //     sel: '.upr-type-selection',
        //     item: '.upr-type'
        // },
        note: {
            main: '.upr-note'
        },
        support: '.cpr-return-support'
    },
    init: function(){
        let _t = this, sel;

        if(uty.detectEl($(_t.el['main'])[0])){
            // Copy to Clipboard the Return Code
            if(uty.detectEl($(_t.el['copy']['main'])[0])){
                $(_t.el['copy']['btn']).on('click', function(){
                    copyToClipboard.init($(_t.el['copy']['body'] + ' b').text(), function(){
                        $(_t.el['copy']['main']).addClass('copied');
                        setTimeout(function(){
                            $(_t.el['copy']['main']).removeClass('copied');
                        }, 3000);
                    });
                });
            }
            // Order Return Type Selection
            // if(uty.detectEl($(_t.el['type']['main'])[0])){
            //     $(_t.el['type']['sel'] + ' select').on('change', function(){
            //         sel = $(this).val();

            //         $(_t.el['type']['item'] + '.active').removeClass('active');
            //         $(_t.el['type']['item'] + '[data-rel="' + sel + '"]').addClass('active');
            //     });
            // }
            // Order Return Note Char Count
            if(uty.detectEl($(_t.el['note']['main'])[0])){
                $(_t.el['note']['main'] + ' textarea').on('keyup', function(){
                    sel = $(this).val().length;
                    $(_t.el['note']['main'] + ' .char span').text(sel);
                });
            }
            // Order Return Support
            $(_t.el['support']).on('click', function(){
                $('.fsm-trigger').trigger('click');
            });
            
        }
    }
}

// Code Copier
let codeCopier = {
    el: {
        main: '.copy'
    },
    init: function(){
        let _t = this, prn, trg;
        
        if(uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['main']).on('click', function(){
                prn = $(this).parents($(this).attr('data-parent'));
                trg = $(this).attr('data-target');
                copyToClipboard.init($(trg, prn).text(), function(){
                    $(prn).addClass('copied');
                    setTimeout(() => {
                    $(prn).removeClass('copied'); 
                    }, 3000);
                });
            });
        }
    }
};

// Lists Swiper
let plgLists = {
    el: {
        main: '.plg-lists'
    },
    init: function(){
        let _t = this, cls = 'swiper-activated', swp = $(_t.el['main'] + ' .swiper-container');

        if(! isMobile && uty.detectEl($(_t.el['main'])[0])){
            if ( ! swp.hasClass(cls)) {
                swp.attr('data-swiper', 'main').addClass(cls);
                new MinusSwiper({ ID: swp[0] });
            }
        }
    }
};

// User Menu
let userMenu = {
    el: {
        main: '.usm'
    },
    init: function(){
        let _t = this, act = $(_t.el['main'] + ' .selected');
        
        if(isMobile && uty.detectEl(act[0]))
            $(_t.el['main'] + ' .scrollable-h').scrollLeft(act.position().left - 24);
    }
};

// User Gallery
let userGallery = {
    el: {
        main: '.thumb-medias',
        exclude: '.thumb-excluded',
        mnp: '#mnp-thumb-gallery'
    },
    template: {
        slider: '<div class="mnp mnp-target mnp-m-center mnp-d-center mnp-image" id="mnp-thumb-gallery"><div class="mnp-header"><button class="mnp-close btn-close-layer" type="button" aria-label="close"></button></div><div class="mnp-body"><div class="thumb-gallery"><div class="swiper-container" data-swiper="thumbGallery"><div class="swiper-wrapper">{{SLIDES}}</div><div class="swiper-pagination"></div></div><button type="button" class="thumb-button-prev"></button><button type="button" class="thumb-button-next"></button></div></div></div>',
        slide: '<div class="swiper-slide"><div class="thumb-img"><img src="{{SRC}}" /></div></div>'
    },
    prepare: function(ths){
        let _t = this, html = '', prn = $(ths).parents(_t.el['main']), src = $(ths).attr('href'), inx = 0;

        $('a', prn).not(_t.el['exclude']).each(function(i){
            if($(this).attr('href') == src)
                inx = i;
            html += _t.template.slide.replace('{{SRC}}', $(this).attr('href'));
        });

        body.append(_t.template['slider'].replace('{{SLIDES}}', html));

        mnp.open({trigger: ths, target: $(_t.el['mnp'])[0], close: $('.mnp-close, #overlay')}, false, function(){
            $(_t.el['mnp'] + ' .swiper-container')[0].destroy();
            $(_t.el['mnp']).remove();
        });
        
        new MinusSwiper({ ID: $(_t.el['mnp'] + ' .swiper-container')[0] });
        $(_t.el['mnp'] + ' .swiper-container')[0].focused(inx);
    },
    init: function(){
        let _t = this;
        
        if(uty.detectEl($(_t.el['main'])[0]))
            $(_t.el['main'] + ' a').not(_t.el['exclude']).off('click').on('click', function(e){
                e.preventDefault();
                _t.prepare(this);
            });
    }
};