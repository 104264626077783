let lazy, datepicker;

function Main() {
    this.init();
}

Main.prototype = {
    constructor: Main,
    initPlugins: function (scope) {
        let _t = this;

        // IOS Prevent Zoom Action
        // document.addEventListener('gesturestart', function(e) {
        //     e.preventDefault();
        //     document.body.style.zoom = 0.99;
        // });
        // document.addEventListener('gesturechange', function(e) {
        //     e.preventDefault();
        //     document.body.style.zoom = 0.99;
        // });
        // document.addEventListener('gestureend', function(e) {
        //     e.preventDefault();
        //     document.body.style.zoom = 0.99;
        // });

        // LAZYLOAD
        lazy = new LazyLoad({
            elements_selector: '.lazy',
            cancel_on_exit: false,
            callback_loaded: function (elm) {
                $(elm).parents('.responsive').addClass('img-loaded');
                if (elm.tagName != 'IMG' && elm.hasAttribute('data-bg'))
                    elm.classList.add('bg-loaded');
            }
        });

        // LIBS
        inputMask.init();
        // 3.accordion.js
        if(uty.detectEl($('.accordion')[0]))
            accordion.init(document, false);
            // accordion.init(document, false, function(){
            //     if(uty.detectEl($('.plp-filters')[0]))
            //         siderbar.updateSticky();
            // });
        
        // 3.1.toggler.js
        toggler.init();
        scrollToTarget.init();

        // 4.mnp.js
        mnp.init();

        // 7.customMap.js
        // storesModal.init();
        stores.init();
        carrefoursaStores.init();

        // 9.alert.js
        floatingAlert.init();
        dismissibleAlert.init();

        // SWIPER
        var swiperElem = scope.querySelectorAll('[data-swiper]'),
            swiperTrigger = function () {
                uty.forEach(swiperElem, function (index, elm) {
                    var cls = 'swiper-activated';
                    if ( ! $(elm).hasClass(cls)) {
                        elm.classList.add(cls);
                        new MinusSwiper({ ID: elm });
                    }
                });
            };

        if (swiperElem.length > 0 && typeof MinusSwiper !== 'undefined')
            swiperTrigger();
        
        // Pikaday
        if(uty.detectEl($('[data-datepick]')[0])){
            let dpConfig;

            $.cachedScript(extJSPath + 'pikaday.min.js').done(function(script, textStatus) {
                $('[data-datepick]').each(function(){
                    dpConfig = Object.assign({}, SITE_CONFIG.plugins.pikaday.sets[$(this).attr('data-datepick')]);
                    dpConfig['field'] = this;
                    dpConfig['container'] = $(this).parents('.wrp')[0];

                    for(i in SITE_CONFIG.plugins.pikaday.defaults)
                        dpConfig[i] = SITE_CONFIG.plugins.pikaday.defaults[i];

                    datepicker = new Pikaday(dpConfig);
                    $(this).parents('.input').addClass('input-datepick');
                });
            });
        }

        // Popper Init
        popperCustom.init();

        // 1.main-menu.js
        mainMenu.init();
        searchbox.init();
        footer.init();
        fsm.init();

        // 2.forms.js
        inputLabel.init();
        showPassword.init();
        SSHServiceModelSearch.init();
        sellerRegisterForm.init();

        // 3.plp.js
        plpEvents.init();
        // seoContent.init();
        similarCampaigns.init();

        // 4.pdp.js
        pdpGallery.init();
        pdpSections.init();
        pdpBenefits.init();
        breadcrumb.init();
        createList.init();
        asqRules.init();
        shareProduct.init();
        prdcode.init();
        creditOfferBadge.init();

        // 5.Compare
        comparePage.init();

        // 6.home.js
        homeFeaturedSwiper.init();
        expandableProductWidget.init();
        corporatePages.init();
        atsController.init();
        corporateMenu.init();
        mediaPopup.init();
        categorySwiper.init();
        recentProducts.init();
        MPStoreMenu.init();
        SSHStoreFinder.init();
        SSHMenu.init();
        // LPSubtab.init();
        curatedShare.init();
        campaignShare.init();
        SSHWarrantyCompare.init();

        // 7.member.js
        // loginRegister.init();
        chaAccordion.init();
        forgotPasswordOpt.init();
        memberSidebar.init();
        orderCancel.init();
        orderReturn.init();
        codeCopier.init();
        userMenu.init();
        userGallery.init();

        // 8.checkout.js
        cartSpecificOffers.init();
        cartSum.init();
        cartCouponbox.init();
        checkoutAccordion.init();
        addNewAddress.init();
        deliveryOptions.init();
        plgLists.init();

        
        if(isMobile){
            // Animate on Scroll
            scrollEffector.init('.se-item', SITE_CONFIG.plugins.gsap.mobile);
            // Scrollable Tabs Selected
            $('.nav.scrollable-h').each(function(){
                if(uty.detectEl($('.active', this)[0]))
                    $(this).scrollLeft($('.active', this).position().left - 20);
            });
        }else{
            // Animate on Scroll
            scrollEffector.init('.se-item', SITE_CONFIG.plugins.gsap.desktop);
        }
    },
    init: function () {
        let _t = this;
        _t.initPlugins(document);
    }
};

new Main();

function ON_READY() {
    $('body').removeClass('holding');
}
stage.addEventListener("CustomEvent", [{ type: 'EVENTS_ON_READY', handler: 'ON_READY' }]);

// Custom Event Dispacher
let dispatcher = function (o) {
    o = o || {};
    let type = o['type'] || '',
        data = o['data'] || {};

    if (typeof stage !== 'undefined' && type != '')
        stage.dispatchEvent("CustomEvent", type, data);
};

function ON_SWIPER_EVENT(o) {
    let ID = o['ID'],
        type = o['type'] || '';
    /* 
        compare
    */
    let _ID = $(ID),
        k = _ID.attr('data-swiper') || '';
    if (k == 'compare' && type == 'slideChangeTransitionEnd') {
        let activePagination = _ID.find('.swiper-pagination-bullet-active').index() || 0;

        $('.compare-swiper .swiper-pagination').each(function(){
            let ths = $( this );
            ths.find('> span').eq( activePagination ).trigger('click');
        });
    }
}
stage.addEventListener("CustomEvent", [{ type: 'SWIPER_ACTIVE_ELEMENT', handler: 'ON_SWIPER_EVENT' }]);
