let cartSpecificOffers = {
    el: {
        main: '.csc',
        btn: '.csc-toggle .link-down',
        info: '.csc-item-info',
        detail: '.csc-item-detail',
        mnp: '#mnp-campaign-details',
        mnpBody: '#campaign-details',
        bank: {
            main: '.boi',
            detail: '.boi-detail',
            info: '.boi-link'
        }
    },
    init: function(){
        let _t = this, cont;

        if(uty.detectEl($(_t.el['main'])[0])){
            // Collapse / expand
            $(_t.el['btn']).on('click', function(){
                $(this).parents(_t.el['main']).toggleClass('active');
            });

            // Offer Detail
            // $(_t.el['info']).on('click', function(){
            //     cont = $(this).parents('.csc-item').find(_t.el['detail']).html();
            //     $(_t.el['mnpBody']).html(cont);
            //     mnp.open({trigger: this, target: $(_t.el['mnp'])[0], close: $('.mnp-close, #overlay')});
            // });
        }

        // Bank Offers
        if(uty.detectEl($(_t.el['bank']['main'])[0])){
            $(_t.el['bank']['info']).on('click', function(){
                cont = $(this).parents(_t.el['bank']['main']).find(_t.el['bank']['detail']).html();
                $(_t.el['mnpBody']).html(cont);
                mnp.open({trigger: this, target: $(_t.el['mnp'])[0], close: $('.mnp-close, #overlay')});
            });
        }
    }
};

let cartSum = {
    el: {
        main: '.cart-sum',
        container: '.checkout-grid',
        sidebar: '.checkout-aside',
        inner: '.checkout-sidebar',
        btn: '.cart-sum-total'
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['main'])[0])){
            if(isMobile){
                $(_t.el['btn']).on('click', function(){
                    mnp.open({trigger: this, target: $(_t.el['main'])[0], close: $('.cart-sum-close, #overlay'), class: 'cart-sum-ready'});
                });
            }else{
                siderbar = new StickySidebar(_t.el['sidebar'], {
                    containerSelector: _t.el['container'],
                    innerWrapperSelector: _t.el['inner'],
                    topSpacing: 20,
                    bottomSpacing: 0
                });
            }
        }
    }
};

let cartCouponbox = {
    el: {
        main: '.cpn-main',
        mainTrigger: '.cpn-header button',
        list: '.cpn-list',
        listTrigger: '.cpn-list-toggle button',
        coupon: {
            main: '.cpn-list-items, .ucl-list',
            item: '.cli',
            btn: '.cli-toggle'
        }
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['main'])[0])){
            // Coupon Box Collapse/Expand
            $(_t.el['mainTrigger']).on('click', function(){
                $(_t.el['main']).toggleClass('active');
                if( ! isMobile)
                    siderbar.updateSticky();
            });

            // Coupon List Collapse/Expand
            $(_t.el['listTrigger']).on('click', function(){
                $(_t.el['list']).toggleClass('active');
                if( ! isMobile)
                    siderbar.updateSticky();
                if($(_t.el['list']).hasClass('active'))
                    uty.pageScroll({top: $(_t.el['list']).offset().top});
            });
        }

        // Coupon Item Detail Collapse/Expand
        if(uty.detectEl($(_t.el['coupon']['main'])[0])){
            accordion.init(document, {main: _t.el['coupon']['main'], item: _t.el['coupon']['item'], btn: _t.el['coupon']['btn']});
        }
    }
};

// Checkout
let checkoutAccordion = {
    el: {
        page: '.page-payment',
        main: '.cpb-accordion',
        item: '.cps',
        btn: '.cps-header'
    },
    init: function(){
        let _t = this, elm;

        // if(uty.detectEl($(_t.el['main'])[0])){
        //     accordion.init(document, {main: _t.el['main'], item: _t.el['item'], btn: _t.el['btn']}, function(ths){
        //         uty.pageScroll({top: $(ths).offset().top});
        //         if(uty.detectEl($('.swiper-container', ths)[0]))
        //             $('.swiper-container', ths).each(function(){
        //                 this.update();
        //             });
        //     });
        // }

        // Payment Types Accordion
        if(uty.detectEl($('#payment-options')[0]))
            accordion.init(document, {main: '#payment-options', item: '.pop', btn: '.pop-header'}, function(ths){
                uty.pageScroll({top: $(ths).offset().top});
            });
        
        // Payment Credit & Transfer Accordion
        if(uty.detectEl($('.scp-accordion')[0]))
            accordion.init(document, {main: '.scp-accordion', item: '.sci', btn: '.sci-header button'}, function(ths){
                if(uty.detectEl($(_t.el['page'])[0]))
                    uty.pageScroll({top: $(ths).offset().top});
            });
        
        // Payment Legal Texts
        if(uty.detectEl($('.cart-sum-confirm')[0])){
            $('.cart-sum-confirm .link-inline').on('click', function(){
                $('.cps-agreements').addClass('active');
                elm = $($(this).attr('data-target'));
                uty.pageScroll({top: elm.offset().top});
            });
        }
    }
};

let addNewAddress = {
    el: {
        main: '#modal-select-address',
        btn: '.asl-new .btn-add',
        modal: '#modal-new-address'
    },
    init: function(){
        let _t = this;

        if(uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['main'] + ' ' + _t.el['btn']).on('click', function(){
                $(_t.el['main']).on('hidden.bs.modal', function (event) {
                    $(_t.el['modal']).modal('show');
                    $(_t.el['main']).off('hidden.bs.modal');
                });
                $(_t.el['main']).modal('hide');
            });
        }
    }
};

let deliveryOptions = {
    el: {
        main: '.dop-options',
        item: '.doi',
        radio: '.doi-select input:radio'
    },
    init: function(){
        let _t = this, prn;

        if(uty.detectEl($(_t.el['main'])[0])){
            $(_t.el['radio']).on('change', function(){
                prn = $(this).parents(_t.el['main']);

                if($(this).prop('checked')){
                    $(_t.el['item'] + '.selected', prn).removeClass('selected');
                    $(this).parents(_t.el['item']).addClass('selected');
                }
            });
        }
    }
};

// Credit Card
function CreditCard(o) {
    o = o || {};
    let form = $(o['form'] || ''), // <form> tag
        container = $(o['container'] || ''); // Card Image Container
    if (container.length > 0 && form.length > 0) {
        new Card({
            form: form.get(0),
            container: o['container'] || '',
            formSelectors: {
                numberInput: o['numberInput'] || '',
                expiryInput: o['expiryInput'] || '',
                cvcInput: o['cvcInput'] || '',
                nameInput: o['nameInput'] || ''
            }
        });

        $( o['numberInput'] || '' )
        .add( $( o['expiryInput'] || '' ) )
        .add( $( o['cvcInput'] || '' ) )
        .add( $( o['nameInput'] || '' ) )
        .on('focus', function(){
            $( this ).parents( o['parents'] || '' ).addClass('credit-card-active');
        });
        // .on('blur', function(){
        //     $( this ).parents( o['parents'] || '' ).removeClass('credit-card-active');
        // });
    }
}