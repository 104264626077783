var comparePage = {
    el: {
        page: '.page-compare',
        main: '#compare-products',
        swiper: '.compare-swiper.has-rows',
        row: '.cpp-row',
        diff: '.cpp-diff input:checkbox',
        exp: '.cpp-collapse button',
        acc: {
            main: '.cpp-accordion',
            item: '.acc-item',
            btn: '.acc-header > button'
        }
    },
    cls: {
        different: 'different',
        same: 'same',
        visibilty: 'none'
    },
    barH: (isMobile) ? 54 : 63,
    diff: function(val){
        let _t = this;

        if(val){
            uty.pageScroll({top: $(_t.el['acc']['main']).offset().top - ($('.header').innerHeight() + _t.barH)});
            $(_t.el['swiper']).each(function (){
                let swiper = $(this),
                    sld = swiper.find('.swiper-slide');

                sld.eq(0).each(function () {
                    let ths = $(this);

                    ths.find(_t.el['row']).each(function (ind) {
                        let item = $(this),
                            value = (item.find('b').text() || '').trim(),
                            target = sld.not(':eq(0)'),
                            b = false;

                        target.each(function () {
                            let targetItem = $(this).find(_t.el['row']).eq(ind),
                                targetValue = (targetItem.find('b').text() || '').trim();

                            if(targetValue != value) {
                                targetItem.addClass(_t.cls.different);
                                b = true;
                            }else
                                targetItem.addClass(_t.cls.same);
                        });

                        if(b){
                            item.addClass(_t.cls.different);
                            target.each(function(){
                                $(this).find(_t.el['row']).eq(ind).removeClass(_t.cls.same).addClass(_t.cls.different);
                            });
                        }else
                            item.addClass(_t.cls.same);
                    });

                    // Hide the entire prop set
                    if (ths.find(_t.el['row']).length == ths.find(_t.el['row'] + '.' + _t.cls.same).length)
                        sld.parents(_t.el['acc']['item']).eq(0).addClass(_t.cls['visibilty']);
                });
            });
        }else{
            $(_t.el['main']).find('.' + _t.cls.visibilty).removeClass(_t.cls.visibilty);
            $(_t.el['main']).find('.' + _t.cls.different).removeClass(_t.cls.different);
            $(_t.el['main']).find('.' + _t.cls.same).removeClass(_t.cls.same);
        }
    },
    expand: function(val){
        let _t = this;

        if(val){
            $(_t.el['acc']['main'] + ' ' + _t.el['acc']['item']).addClass('active');
            uty.pageScroll({top: $(_t.el['acc']['main']).offset().top - ($('.header').innerHeight() + _t.barH)});
            $(_t.el['swiper']).each(function(){
                this.update();
            });
        }else
            $(_t.el['acc']['main'] + ' ' + _t.el['acc']['item']).removeClass('active'); 
    },
    addEvent: function(){
        let _t = this;

        // Show Differences
        $(_t.el['diff']).on('change', function(){
            _t.diff($(this).prop('checked'));
        });

        // Expand All
        $(_t.el['exp']).on('click', function(){
            _t.expand(!$(this).hasClass('active'));
            $(this).toggleClass('active');
        });

        // Accordion
        accordion.init($(_t.el['main'])[0], _t.el['acc'], function(ths){
            ths.querySelector(_t.el['swiper']).update();
        });
    },
    init: function () {
        var _t = this;

        if(uty.detectEl($(_t.el.page)[0]))
            _t.addEvent();
    }
};
