const mainMenu = {
    el: {
        main: '#main-menu',
        catLv1Link: '.hmm-menu-parent',
        catLv1Hover: '.hb3-cat',
        catLv1Wrp: '.mmn-lv1',
        catLv2: '.mmn-lv2 > .mmn-link',
        back: '.hmm-back',
        close: '.hmm-close',
        btnSupport: '.header .btn-support',
        menuSupport: '.hb3-support-menu',
        wrpSupport: '.hb3-support',
        btnUser: '.btn-user',
        menuUser: '#user-menu'
    },
    cls: {
        active: 'active',
        lv1: 'mmn-lv1-ready',
        open: 'opened'
    },
    actLv1: function(ths){
        let _t = this, id = ths.attr('rel');

        $(_t.el['catLv1Link'] + '.' + _t.cls['active']).removeClass(_t.cls['active']);
        $(_t.el['catLv1Hover'] + '.' + _t.cls['active']).removeClass(_t.cls['active']);
        ths.addClass(_t.cls['active']);

        if(! uty.detectEl($('.' + _t.cls['lv1'])[0]))
            $(_t.el['main']).addClass(_t.cls['lv1']);
        
        $(_t.el['catLv1Wrp'] + '.' + _t.cls['active']).removeClass(_t.cls['active']);
        $(id).addClass(_t.cls['active']);
    },
    resetLv1: function(){
        let _t = this;

        $(_t.el['main']).removeClass(_t.cls['lv1']);
        $(_t.el['catLv1Wrp'] + '.' + _t.cls['active']).removeClass(_t.cls['active']);
    },
    addEvent: function(){
        let _t = this, timer, ths;

        if(isMobile){
            // Menu
            $('.header .btn-menu').on('click', function(){
                _t.destroyAll();
                mnp.open({trigger: this, target: $('#main-menu')[0], class: 'main-menu-ready', close: $('.hmm-close, #overlay')}, {active: 'active', body: 'mnp-ready'});
            });
            // Level 1
            $(_t.el['catLv1Link']).on('click', function(){
                _t.actLv1($(this));
            });
            // Back Func
            $(_t.el['back']).on('click', function(){
                _t.resetLv1();
            });
            // Close Func
            $(_t.el['close']).on('click', function(){
                mnp.destroy({trigger: $('.btn-menu')[0], target: $(_t.el['main'])[0], class: 'main-menu-ready'}, {active: 'mnp-active', body: 'mnp-ready'});
                _t.resetLv1();
            });
            // Level 2
            accordion.init($(_t.el['main'])[0], {main: '.mmn-sub-lv1 > ul', item: '.mmn-lv2', btn: '.mmn-link-lv2'});
            // User Menu
            $('.hmm-user-menu').on('click', function(){
                _t.destroyAll();
                mnp.open({trigger: this, target: $(_t.el['menuUser'])[0], class: 'user-menu-ready', close: $('.hum-close, #overlay')}, {active: 'active', body: 'mnp-ready'});
            });
            if(uty.detectEl($('.user-loggedin')[0]))
                $(_t.el['btnUser']).on('click', function(e){
                    e.preventDefault();
                    mnp.open({trigger: this, target: $(_t.el['menuUser'])[0], class: 'user-menu-ready', close: $('.hum-close, #overlay')}, {active: 'active', body: 'mnp-ready'});
                });
            // Support Menu
            accordion.init($(_t.el['main'])[0], {main: '.hmm-smenu', item: '.smenu-support', btn: '.smenu-support-link'});
        }else{
            // Main Menu
            $('<button class="btn-menu-close" type="button"></button>').insertAfter('.header .btn-menu');
            $('.header .btn-menu').on('click', function(){
                _t.destroyAll();
                mnp.open({trigger: this, target: $('#main-menu')[0], class: 'main-menu-ready', close: $('.header .btn-menu-close, #overlay')}, {active: 'active', body: 'mnp-ready'});

                if( ! uty.detectEl($(_t.el['catLv1Link'] + '.' + _t.cls['active'])[0]))
                    _t.actLv1($(_t.el['catLv1Link'] + ':eq(0)'));
            });
            // Level 1
            $(_t.el['catLv1Link']).on('mouseenter', function(){
                _t.actLv1($(this));
            });
            // Level 1 Featured Categories
            $(_t.el['catLv1Hover']).on('mouseenter', function(){
                ths = this;
                timer = setTimeout(function(){
                    _t.destroyAll();
                    mnp.open({trigger: ths, target: $('#main-menu')[0], class: 'main-menu-hover', close: $('.header .btn-menu-close, #overlay')}, {trigger: 'active', active: 'active', body: 'mnp-ready'});
                    $('#overlay, .hdr-block1, .hdr-block2, .hb3-col1, .hb3-col3').off('mouseenter').on('mouseenter', function(){
                        mnp.destroy({trigger: ths, target: $('#main-menu')[0], class: 'main-menu-hover'}, {active: 'active', trigger: 'active', body: 'mnp-ready'});
                        $('#overlay, .hdr-block1, .hdr-block2, .hb3-col1, .hb3-col3').off('mouseenter');
                    });
                    _t.actLv1($(ths));
                }, 200);
            });
            $(_t.el['catLv1Hover']).on('mouseleave', function(){
                clearTimeout(timer);
            });
            // Support Menu
            $(_t.el['btnSupport']).on('click', function(){
                _t.destroyAll();
                mnp.open({trigger: this, target: $(_t.el['menuSupport'])[0], class: 'support-menu-ready', close: $('.hb3-support-close, #overlay')}, {active: 'active', body: 'mnp-ready'});
            });
            // User Menu
            $(_t.el['btnUser']).on('click', function(e){
                e.preventDefault();
                _t.destroyAll();
                mnp.open({trigger: this, target: $(_t.el['menuUser'])[0], class: 'user-menu-ready', close: $('.hum-close, #overlay')}, {active: 'active', body: 'mnp-ready'});
            });
        }
    },
    destroyAll: function(){
        let _t = this;

        // Menu Button
        if(uty.detectEl($('.main-menu-ready')[0]))
            mnp.destroy({trigger: $('.btn-menu')[0], target: $(_t.el['main'])[0], class: 'main-menu-ready'}, {active: 'active', body: 'mnp-ready'});
        
        // Menu Hover
        if(uty.detectEl($('.main-menu-hover')[0]))
            mnp.destroy({trigger: $(_t.el['catLv1Hover'] + '.' + _t.cls['active'])[0], target: $(_t.el['main'])[0], class: 'main-menu-hover'}, {active: 'active', body: 'mnp-ready'});
        
        // Support Menu
        if(uty.detectEl($('.support-menu-ready')[0]))
            mnp.destroy({trigger: $(_t.el['btnSupport'])[0], target: $(_t.el['menuSupport'])[0], class: 'support-menu-ready'}, {active: 'active', body: 'mnp-ready'});
        
        // User Menu
        if(uty.detectEl($('.user-menu-ready')[0]))
            mnp.destroy({trigger: $(_t.el['btnUser'])[0], target: $(_t.el['menuUser'])[0], class: 'user-menu-ready'}, {active: 'active', body: 'mnp-ready'});
    },
    init: function(){
        let _t = this;
        
        if(uty.detectEl($(_t.el['main'])[0]))
            _t.addEvent();
    }
};

const searchbox = {
    el: {
        main: '.sbx',
        btn: '.header .btn-search',
        input: '.sbx-input input',
        close: '.sbx-back',
        clear: '.sbx-clear',
        img: '.sbx-img',
        mic: '.sbx-mic',
        micClose: '.sbv-close button'
    },
    addEvent: function(){
        let _t = this;

        // Search
        $(_t.el['btn']).on('click', function(){
            _t.suggest.clear();
            mainMenu.destroyAll();
            mnp.open({trigger: this, target: $(_t.el['main'])[0], class: 'searchbox-ready', close: $(_t.el['close'] + ', .hdr-overlay, #overlay')}, {active: 'active', body: 'mnp-ready'}, function(){
                $(_t.el['main']).removeClass('sbx-img-active').removeClass('sbx-mic-active');
                _t.suggest.init();
            });
            $(_t.el['input']).trigger('focus');
        });
        // Clear
        $(_t.el['clear']).on('click', function(){
            _t.clear();
        });
        // Search by Image
        $(_t.el['img']).on('click', function(){
            $(_t.el['main']).removeClass('sbx-mic-active');
            $(_t.el['main']).addClass('sbx-img-active');
            if(!uty.detectEl($('.searchbox-ready')[0])){
                $(_t.el['btn']).trigger('click');
            }
            stage.dispatchEvent("CustomEvent", "ADVANCED_SEARCH_ACTIVE", {type: 'img'});
        });
        // Search by Mic
        $(_t.el['mic']).on('click', function(){
            $(_t.el['main']).removeClass('sbx-img-active');
            $(_t.el['main']).addClass('sbx-mic-active');
            if(!uty.detectEl($('.searchbox-ready')[0])){
                $(_t.el['btn']).trigger('click');
            }
            stage.dispatchEvent("CustomEvent", "ADVANCED_SEARCH_ACTIVE", {type: 'mic'});
        });

        if(isMobile){
            $(_t.el['micClose']).on('click', function(){
                $(_t.el['main']).removeClass('sbx-mic-active');
            });
        }

        if(uty.detectEl($('.vsb-voice')[0])){
            $('.vsb-voice .vsb-cta .btn').on('click', function(){
                $(_t.el['mic']).trigger('click');
            });
        }

        if(uty.detectEl($('.vsb-image')[0])){
            $('.vsb-image .vsb-cta .btn').on('click', function(){
                $(_t.el['img']).trigger('click');
            });
        }
    },
    destroy: function(){
        let _t = this;

        if(uty.detectEl($('.searchbox-ready')[0]))
            mnp.destroy({trigger: $(_t.el['btn'])[0], target: $(_t.el['main'])[0], class: 'searchbox-ready'}, {active: 'active', body: 'mnp-ready'});
        
        if(uty.detectEl($('.searchbox-result')[0]))
            _t.clear();

        $(_t.el['main']).removeClass('sbx-img-active').removeClass('sbx-mic-active');
    },
    clear: function(){
        let _t = this;

        body.removeClass('searchbox-result');
        $(_t.el['input']).val('');
    },
    suggest: {
        el: {
            page: '.page-home',
            main: '.sbx-input input'
        },
        timers : {progress: null, typed: null},
        list: [],
        inx: 0,
        placeholder: '',
        typed: function(word, fn) {
            const _t = this, delay = 100, char = word.length;

            let val, i = 0;

            $(_t.el['main']).attr('placeholder', '');

            _t.timers.typed = setInterval(function() {
                val = $(_t.el['main']).attr('placeholder') + word[i];
                $(_t.el['main']).attr('placeholder', val);
                i++;

                if(i == char){
                    clearInterval(_t.timers.typed);

                    if(typeof fn != 'undefined'){
                        fn();
                    }
                }
            }, delay);
        },
        progress: function(ths) {
            let inx =  ths.inx;

            ths.typed(ths.list[inx]);

            if(inx == ths.list.length - 1){
                inx = ths.inx = 0;
            }

            ths.inx++;
        },
        clear: function() {
            const _t = this;

            if(isMobile && uty.detectEl($(_t.el['page'])[0])){
                clearInterval(_t.timers.progress);
                clearInterval(_t.timers.typed);
                $(_t.el['main']).attr('placeholder', _t.placeholder);
                _t.inx = 0;
            }
        },
        init: function() {
            const _t = this, delay = 5000;

            if(isMobile && uty.detectEl($(_t.el['page'])[0])){
                _t.list = $(_t.el['main']).attr('data-keywords').split('|');
                _t.placeholder = $(_t.el['main']).attr('data-placeholder');
                _t.list.push(_t.placeholder);
                _t.typed(_t.placeholder, function(){
                    _t.timers.progress = setInterval(_t.progress.bind(null, _t), delay);
                });
            }
        }
    },
    init: function(){
        let _t = this;
        
        _t.addEvent();
        _t.suggest.init();
    }
};

const footer = {
    el:{
        main: '#site-footer',
        menu: {
            main: '.fb3-col',
            btn: '.fmi-title',
            item: '.fmi'
        }
    },
    menu: function(){
        let _t = this;

        accordion.init($(_t.el['main'])[0], {main: _t.el['menu']['main'], item: _t.el['menu']['item'], btn: _t.el['menu']['btn']});
    },
    init: function(){
        let _t = this;

        if(isMobile && uty.detectEl($(_t.el['main'])[0]))
            _t.menu();
    }
};

const fsm = {
    el: {
        main: '.fsm',
        btn: '.fsm-trigger',
        scroll: '.fsm-back-top'
    },
    init: function(){
        let _t = this, isIOS = new BrowserDetector().isIOS();

        $(_t.el['btn']).on('click', function(){
            $(_t.el['main']).toggleClass('opened');
        });
        // Back to Top
        $(_t.el['scroll']).on('click', function(){
            uty.pageScroll({top: 0});
        });

        // Hide webchat if IOS
        if(isIOS)
            $('.fsm-webchat2').parents('li').addClass('fsm-hidden');
    }
};