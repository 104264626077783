let inputLabel = {
    el: {
        main: '.textbox',
        input: '.textbox input',
        textareaWrp: '.textarea',
        textarea: '.textarea textarea'
    },
    init: function(){
        let _t = this;

        $(_t.el['input']).on('focus', function(){
            $(this).parents(_t.el['main']).addClass('input-active');
        }).on('blur', function(){
            if($(this).val().trim() == '' && $(this).attr('data-datepick') != 'birthdayInline')
                $(this).parents(_t.el['main']).removeClass('input-active');
        });

        $(_t.el['input']).each(function(){
            if($(this).val().trim() != '')
                $(this).parents(_t.el['main']).addClass('input-active');
        });

        $(_t.el['textarea']).on('focus', function(){
            $(this).parents(_t.el['textareaWrp']).addClass('input-active');
        }).on('blur', function(){
            $(this).parents(_t.el['textareaWrp']).removeClass('input-active');
        });
    }
};

let showPassword = {
    el: {
        main: '.form-row.password',
        btn: '.svg-eye-off'
    },
    cls: 'show-pass',
    init: function(){
        let _t = this, prn;

        $(_t.el['btn']).on('click', function(){
            prn = $(this).parents('.textbox');

            if(prn.hasClass(_t.cls))
                prn.find('input').attr('type', 'password');
            else
                prn.find('input').attr('type', 'text');

            prn.toggleClass(_t.cls);
        });
    }
};

let otpTimer = {
    el: {
        main: '.timer',
        time: '.timer-time'
    },
    timer: {},
    time: 0,
    radius: 28,
    template: {
        time: '<span>{{M}}</span><i>:</i><span>{{S}}</span>',
        timer: '<style>.timer-front{stroke-dasharray:{{D}}px; animation:countdown {{R}}s linear forwards;} @keyframes countdown{from{stroke-dashoffset: {{O}}px;}to{stroke-dashoffset: {{D}}px;}}</style><div class="timer-time">{{TIME}}</div><div class="timer-circle"><svg class="timer-svg"><circle class="timer-rear" r="28" cx="30" cy="30"></circle><circle class="timer-front" r="28" cx="30" cy="30"></circle></svg></div>'
    },
    setTemplate: function(ths, time){
        let _t = this, t, ref = Math.ceil(Math.PI * _t.radius * 2);

        if(typeof time != 'undefined'){
            t = _t.time = time;
        }else{
            t = parseFloat($(ths).attr('data-time-total'));
            _t.time = parseFloat($(ths).attr('data-time-remain'));
        }

        // Set Timer HTML
        $(_t.el['time'], ths).html(_t.calcTime());

        // Set Circle Animation
        $(ths).html(_t.template['timer'].replace(/{{D}}/g, ref).replace('{{R}}', _t.time).replace('{{O}}', (t - _t.time) / t * 251).replace('{{TIME}}', _t.calcTime()));
    },
    calcTime: function(){
        let _t = this, m = 0, s = 0;

        if(_t.time < 60){
            s = _t.time;
        }else{
            s = _t.time % 60;
            m = (_t.time - s) / 60;
        }

        if(m < 10)
            m = '0' + m;
        
        if(s < 10)
            s = '0' + s;

        return _t.template['time'].replace('{{M}}', m).replace('{{S}}', s);
    },
    run: function(ths, time){
        let _t = this;

        $(ths).removeClass('timer-completed');
        $(ths).html('');
        _t.setTemplate(ths, time);

        clearInterval(_t.timer);
        _t.timer = setInterval(function(){
            if(_t.time == 0){
                clearInterval(_t.timer);
                $(ths).addClass('timer-completed');
                dispatcher({ type: 'OTP_COUNTDOWN', data: {} });
            }

            $(_t.el['time'], ths).html(_t.calcTime());
            $(ths).attr('data-time-remain', _t.time);

            --_t.time;
        }, 1000);
    }
};

let dynamicDropdown = {
    el: {
        main: '.dropdown-toggle',
        menu: '.dropdown-menu',
        item: '.dropdown-item'
    },
    init: function(){
        let _t = this, trg, prn;

        $(_t.el['item']).on('click', function(){
            prn = $(this).parents(_t.el['menu']);
            trg = prn.siblings(_t.el['main']);
            trg.find('.choice').html($(this).html());
            prn.find('.selected').removeClass('selected');
            $(this).addClass('selected');
        });
    }
};

let SSHServiceModelSearch = {
    el: {
        main: '.ssr-model',
        result: '.ssr-model-result',
        suggest: '.ssr-model-suggest',
        input: '.ssr-model-input input',
        trigger: '.ssr-model-trigger',
        search: '.ssr-model-search'
    },
    template: {
        opt: '<button type="button" rel="{{VAL}}">{{OPT}}</button>',
        noresult: '<div class="ssr-model-noresult"><div>Uygun sonuÃ§ bulunmadÄ±</div><a class="btn btn-secondary" href="#">Model Bilgisi Girin</a></div>'
    },
    bind: function() {
        const _t = this;

        $(_t.el['trigger']).on('click', function(){
            $(_t.el['main']).toggleClass('ssr-model-active');
        });

        $(_t.el['input']).on('keyup', function() {
            const str = $(this).val();
            const result = [];
            let html = '';

            if(str.length > 2){
                $(_t.el['main'] + ' select option').each(function(index, value) {
                    const opt = $(value).text();

                    if( opt.toLowerCase().indexOf(str.toLowerCase()) > -1) {
                        result.push({text: $(value).text(), val: $(value).val()});
                    }
                });
                
                result.forEach(function(res){
                    html += _t.template['opt'].replace('{{OPT}}', res.text).replace('{{VAL}}', res.val);
                });

                if(html == ''){
                    html = _t.template['noresult'];
                    $(_t.el['main']).addClass('no-result');
                }else{
                    $(_t.el['main']).removeClass('no-result'); 
                }
            }

            $(_t.el['result']).html(html);

            if($(_t.el['result'] + ' button').length > 0){
                $(_t.el['result'] + ' button').on('click', function(){
                    const val = $(this).attr('rel');

                    $(_t.el['main'] + ' select').val(val).trigger('change');
                    $(_t.el['trigger']).trigger('click');
                });
            }

            // No result state
            if($('.ssr-model-noresult').length > 0){
                $('.ssr-model-noresult a').off('click').on('click', function(e){
                    e.preventDefault();
                    $(_t.el['trigger']).trigger('click');
                    $('#ssr-model-free').removeClass('none');
                });
            }
        });
    },
    init: function() {
        const _t = this;

        if(uty.detectEl($(_t.el['main'])[0])){
            _t.bind();
        }
    }
};

// Seller Register Form
const sellerRegisterForm = {
    el: {
        main: '#seller-register-form',
        multi: '.saf-multiselect select[multiple]',
        url: '.saf-url',
        file: '.saf-file'
    },
    multiSelect: function() {
        const _t = this;

        if(uty.detectEl($(_t.el['multi'])[0])){
            $.cachedScript(extJSPath + 'jquery.multiselect.js').done(function(script, textStatus) {
                $(_t.el['multi']).each(function(){
                    const plh = $(this).attr('data-placeholder'), def = $(this).attr('data-default');
    
                    $(this).multiselect({
                        placeholder: plh,
                        search: true,
                        searchOptions: {
                            default: def
                        },
                        selectAll: true,
                        texts: {
                            selectAll: 'TÃ¼mÃ¼nÃ¼ SeÃ§',
                            unselectAll: 'TÃ¼mÃ¼nÃ¼ SeÃ§',
                            searchNoResult: 'SonuÃ§ BulunamadÄ±'
                        },
                        maxPlaceholderOpts: 9999,
                        onLoad: function(element){
                            const ms = $($(element).attr('data-elm'));
                            $('.ms-options', ms).append('<button class="btn btn-secondary ms-confirm" type="button" disabled>SeÃ§ ve Kapat <i></i></button>');
                            $('.ms-confirm', ms).off('click').on('click', function(){
                                $(ms).removeClass('ms-active');
                            });
                        },
                        onOptionClick: function( element, option ){
                            const ms = $($(element).attr('data-elm'));
                            const len = $('.ms-options > ul input[type="checkbox"]:checked', ms).length;
                        
                            if(len){
                                $('.ms-confirm i', ms).text(`(${len})`);
                                $('.ms-confirm', ms).removeAttr('disabled');
                            }else{
                                $('.ms-confirm i', ms).text('');
                                $('.ms-confirm', ms).attr('disabled');
                            }
                        },
                        onSelectAll: function( element, selected ){
                            const ms = $($(element).attr('data-elm'));
                            
                            if(selected){
                                $('.ms-confirm i', ms).text(`(${selected})`);
                                $('.ms-confirm', ms).removeAttr('disabled');
                                $('.ms-selectall', ms).addClass('selected');
                            }else{
                                $('.ms-confirm i', ms).text('');
                                $('.ms-confirm', ms).attr('disabled');
                                $('.ms-selectall', ms).removeClass('selected');
                            }
                        }
                    });
                });
            });
        }
    },
    url: function() {
        const _t = this;

        if(uty.detectEl($(_t.el['url'])[0])){
            // Add url
            $('.saf-url-cta .btn').on('click', function(){
                
                const prn = $(this).parents('.saf-url');
                const val = $('.saf-url-input input', prn).val().trim();
                
                if(val != ''){
                    prn.addClass('added');
                    $('.saf-url-link').text(val);
                }
            });
            // Reset url
            $('.saf-url-reset').on('click', function(){
                $('.saf-url-input input').val('').trigger('blur');
                $('.saf-url-link').text('');
                $(this).parents('.saf-url').removeClass('added');
            });
        }
    },
    file: function() {
        const _t = this;

        if(uty.detectEl($(_t.el['file'])[0])){
            // File select state
            $('.saf-file-input').on('change', function(e) {
                const val = e.target.files[0].name;
                const prn = $(this).parents('.saf-file');

                prn.addClass('selected');
                $('.saf-file-state', prn).text(val);
            });
            // File remove state
            $('.saf-file-remove').on('click', function() {
                const prn = $(this).parents('.saf-file');
                const val = $('.saf-file-state', prn).attr('data-default');
                
                prn.removeClass('selected');
                $('.saf-file-state', prn).text(val);
            });
        }
    },
    init: function() {
        const _t = this;

        if(uty.detectEl($(_t.el['main'])[0])){
            _t.multiSelect();
            _t.url();
            _t.file();
        }
    }
};